import React, { useEffect, useState } from 'react';
import App from '../containers/App';

const Refresh = () => {
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    const pii = 'You have agreed not to enter any personal identifying information';

    const checkForPiiInElement = (element) => {
      if (element.innerText && element.innerText.includes(pii)) {
        return true;
      }
      for (let child of element.children) {
        if (checkForPiiInElement(child)) {
          return true;
        }
      }
      return false;
    };
  
    const checkForPii = () => {
      const elements = document.body.getElementsByClassName('sendbird-word');
      for (let element of elements) {
        if (checkForPiiInElement(element)) {
          // console.log('pii detected...');
          return true;
        }
      }
      return false;
    };

    const handleCheck = () => {
      if (checkForPii()) {
        setShouldRefresh(true);
      }
    };

    const observer = new MutationObserver(handleCheck);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    const piiCheck = setInterval(handleCheck, 4500);
    return () => {
      observer.disconnect();
      clearInterval(piiCheck);
    }

  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      const timer = setTimeout(() => {
        
        const refreshIcon = document.getElementById('aichatbot-widget-refresh-icon');
        const refreshButton = refreshIcon.closest('div');

        if (refreshButton) {
          // console.log('clicking');
          refreshButton.click();
        };
        setShouldRefresh(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [shouldRefresh]);

  return <App />;
};

export default Refresh;