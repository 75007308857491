import { useEffect } from 'react';

const SendMessage = () => {
    useEffect(() => {
        const sendButtonCheck = setInterval(() => {
            const sendButton = document.querySelector('.sendbird-message-input--send');
            const messageField = document.getElementById('sendbird-message-input-text-field');

            if (sendButton) {
                document.addEventListener('keydown', function(event) {
                    if (event.key === 'Enter') {        
                        if (sendButton) {
                            sendButton.click();

                            setTimeout(function() {
                                while (messageField.innerHTML) {
                                    messageField.innerHTML='';

                                    // console.log('message field cleared. ');
                                    }
                            }, 750);
                        }
                    }
                });
            }
        }, 350);

        return () => {
            clearInterval(sendButtonCheck);
        }
    }, []);
};
export default SendMessage;